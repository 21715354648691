<template>
  <CModal
    :title="$t('create_shipping_method')"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name"
          :isValid="valid_formData_name"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('max_weight')"
          :placeholder="$t('max_weight')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          step="0.01"
          v-model="formData.max_weight"
          :isValid="valid_formData_max_weight"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('basic_shipping_fee')"
          :placeholder="$t('basic_shipping_fee')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          step="0.01"
          v-model="formData.fee"
          :isValid="valid_formData_fee"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('enable')"
          :options="enabledOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.enabled"
          :isValid="valid_formData_enabled"
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('regions') }}
          </label>
          <div class="col-sm-9">
            <multiselect
              v-model="formData.regionsSelect"
              :placeholder="$t('regions')"
              label="label"
              track-by="value"
              :options="regionsOptions"
              :multiple="true"
              :show-labels="false"
              :close-on-select="false"
              :max-height="1000"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'CreateShippingMethodModal',
  components: {
    Multiselect
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      regionsOptions: [],
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.getRegions()
        this.formData = { name: '', max_weight: 0, fee: 0, enabled: 1, regionsSelect: [] }
      }
    }
  },
  computed: {
    valid_formData_name () {
      return this.formData.name ? true : false
    },
    valid_formData_max_weight () {
      return /^[0-9]+(.[0-9]+)?$/.test(this.formData.max_weight) ? true : false
    },
    valid_formData_fee () {
      return /^[0-9]+(.[0-9]+)?$/.test(this.formData.fee) ? true : false
    },
    valid_formData_enabled () {
      return this.formData.enabled === 0 || this.formData.enabled === 1 ? true : false
    },
    valid_submit () {
      return this.valid_formData_name && this.valid_formData_max_weight && this.valid_formData_fee && this.valid_formData_enabled
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getRegions () {
      const loader = this.$loading.show()
      this.$store.dispatch('getRegions').then(res => {
        this.regionsOptions = []
        for (const i in res) {
          this.regionsOptions.push({ value: res[i].id, label: this.$store.getters.locale === 'tw' ? res[i].zh_name : res[i].en_name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('storeShippingMethod', this.$_.extend(this.$_.clone(this.formData), { regions: this.$_.pluck(this.formData.regionsSelect, 'value') })).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
