<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getShippingMethods()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="8" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="dark" block @click="() => editShippingMethodSortModal = { data: { sortData: $_.sortBy(data, 'sort') }, modal: true }">{{ $t('sort') }}</CButton>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createShippingMethodModal = { modal: true }">{{ $t('create_shipping_method') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editShippingMethodModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateShippingMethodModal :data="createShippingMethodModal.data" :show="createShippingMethodModal.modal" @showUpdate="show => createShippingMethodModal.modal = show" />

      <EditShippingMethodModal :data="editShippingMethodModal.data" :show="editShippingMethodModal.modal" @showUpdate="show => editShippingMethodModal.modal = show" />

      <EditShippingMethodSortModal :data="editShippingMethodSortModal.data" :show="editShippingMethodSortModal.modal" @showUpdate="show => editShippingMethodSortModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import CreateShippingMethodModal from './modal/CreateShippingMethodModal'
import EditShippingMethodModal from './modal/EditShippingMethodModal'
import EditShippingMethodSortModal from './modal/EditShippingMethodSortModal'

export default {
  name: 'methods',
  components: {
    CreateShippingMethodModal,
    EditShippingMethodModal,
    EditShippingMethodSortModal
  },
  data () {
    return {
      columns: [ 'name', 'enabled', 'action' ],
      data: [],
      options: {
        headings: {
          name_en: this.$t('name'),
          enabled: this.$t('enable'),
          action: this.$t('action')
        },
        sortable: [ 'name', 'enabled' ],
        filterable: [ 'name', 'enabled']
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      createShippingMethodModal: { data: {}, modal: false },
      editShippingMethodModal: { data: {}, modal: false },
      editShippingMethodSortModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getShippingMethods () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getShippingMethods', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
